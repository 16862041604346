import Hls from 'hls.js';
import store from '@/store';
/**
 * @description 브랜드 비디오 객체
 */
const brandVideoObject = {
  brandVideo: null,
  brandHls: null,
  config: {
    enableWorker: true,
    lowLatencyMode: true,
    backBufferLength: 90
  },
  /**
   * @description 브랜드 muted
   * @author CHOI DAE GEON
   */
  getMuted () {
    return this.brandVideo.muted;
  },
  /**
   * @description 비디오 Element 객체 세팅
   */
  onBrandPlay () {
    return this.brandVideo.play();
  },
  /**
   * @description 비디오 mute 설정
   */
  onBrandMute (val) {
    this.brandVideo.muted = val;
  },
  /**
   *
   */
  onBrandPause () {
    return this.brandVideo.pause();
  },
  /**
   * @description 비디오 Element 객체 세팅
   * @author CHOI DAE GEON
   */
  setBrandVideo () {
    this.brandVideo = document.querySelector('#brand_video');
  },
  /**
   * @description 브랜드음원 세팅
   * @param {*} url
   */
  playBrandMusic (url) {
    this.setBrandVideo();

    if (Hls.isSupported()) {
      // const tg = 'https://www.aplayz.co.kr' + url;
      const tg = window.location.host.indexOf('localhost') !== -1 ? 'http://13.209.152.125:10084' + url : url;
      if (store.state.brandhls !== undefined) store.state.brandhls.destroy(); // removed all listeners
      this.brandHls = new Hls(this.config);
      this.brandHls.loadSource(tg);
      this.brandHls.attachMedia(this.brandVideo);
      store.commit('setBrandHls', this.brandHls);
    }
  },
  /**
   * @description 에러함수 등록
   * @author CHOI DAE GEON
   * @param {*} fn
   */
  registerBrandHlsErrorHandle (fn) {
    this.brandHls.on(Hls.Events.ERROR, fn);
  },
  /**
   * @description  이벤트 등록
   * @param {*} type 이벤트 타입
   * @param {*} fn 이벤트 트리거가 될때 발생할 함수
   * @author CHOI DAE GEON
   */
  registerBrandVideoEvent (type, fn) {
    if (this.brandVideo) {
      this.brandVideo.addEventListener(type, fn);
    }
  },
  /**
   * @description 일회성 이벤트
   * @param {*} type 이벤트 타입
   * @param {*} fn 이벤트 트리거가 될때 발생할 함수
   * @author CHOI DAE GEON
   */
  registerBrandVideoOnceEvent (type, fn) {
    if (this.brandVideo) {
      this.brandVideo.addEventListener(type, fn, { once: true });
    }
  },
  /**
   * @description  이벤트 삭제
   * @param {*} type 이벤트 타입
   * @param {*} fn 삭제할 함수
   * @author CHOI DAE GEON
   */
  removeBrandVideoEvent (type, fn) {
    if (this.brandVideo) {
      this.brandVideo.removeEventListener(type, fn);
    }
  },
  /**
   * @description  브랜드 hls 삭제
   */
  destroyBrandHls () {
    if (this.brandHls) this.brandHls.destroy();
  },
  /**
   * @description 브랜드음원 중지상태
   */
  getPaused () {
    this.setBrandVideo();
    return this.brandVideo.paused;
  },
  setBrandVideoVolume (val) {
    if (this.brandVideo) this.brandVideo.volume = val;
  }
};

export { brandVideoObject };
